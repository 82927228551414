import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import { getLinkType } from 'helpers/utils';
import scrollTo from 'helpers/scrollTo';

/**
 * Wrappt beliebige Kinder mit einem Link
 * Dabei wird je nachdem, wohin der Link geht (Inter, Exter, Anker)
 * der Korrekte Wrapper verwendet. Ohne Styles. Ohne Schnick-Schnack.
 * @param {string} props.children Der Inhalt des Buttons (zumeist Text)
 * @param {string} props.to: Link des Buttons (kann intern, extern, Anker sein)
 */
const LinkWrapper = ({ className, to, scrollOffset, children, title }) => {
    const linkType = getLinkType(to);

    // Externer Link
    let Element = (
        <a className={className} href={to} target="_blank" rel="noopener noreferrer" title={title}>
            {children}
        </a>
    );

    // Interner (Gatsby-Link)
    if (linkType === 'internal') {
        Element = (
            <Link className={className} to={to} title={title}>
                {children}
            </Link>
        );
        // Anchor
    } else if (linkType === 'anchor') {
        Element = (
            <a
                className={className}
                href={to}
                onClick={scrollTo}
                data-scroll-offset={scrollOffset}
                title={title}
            >
                {children}
            </a>
        );
    }

    return Element;
};

LinkWrapper.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
    to: PropTypes.string.isRequired,
    scrollOffset: PropTypes.number,
    title: PropTypes.string,
};

LinkWrapper.defaultProps = {
    className: null,
    scrollOffset: 0,
    title: '',
};

export default LinkWrapper;
