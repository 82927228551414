import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { gaps } from 'helpers/variables';
import {
    gapable,
    layerable,
    sizable,
    textAlignable,
    displayable,
    justifyable,
    roundable,
    itemsAlignable,
    flexDirectionable,
} from 'helpers/traits';
import { mq } from 'helpers/stylehelpers';

/**
 * Container
 * ----------------------------------------------------------------------------
 * Der Standard-Container bildet einen Wrapper für jeglichen Content.
 *
 * @param {string} props.alignItems Optional: Die Ausrichtung der Kinder bei display="flex"
 * @param {node} props.children Inhalte der Section
 * @param {bool} props.cols Optional: Anzahl der Spalten im Grid-Modus
 * @param {string} props.display Optional: die Darstellung (block, inline, ...)
 * @param {string} props.gap Optional: der Abstand nach unten ('s', 'm', 'l', 'xl', 'xxl', 'xxxl')
 * @param {bool} props.grid Optional: Grid aktivieren
 * @param {string} props.gridGap Optional: Grid Gap Größe (bspw. "2em")
 * @param {string} props.justify Optional: Ausrichtung des Inhalts
 * @param {number} props.layer Optional: die Ebene/Größe des Schattens
 * @param {string} props.layout Optional: Layout anhand eines 12er-Grids (bspw. '3-9' => | 3fr | 9fr | => | 1/3 | 2/3 |)
 * @param {bool} props.padded Optional: Container mit Padding versehen
 * @param {string} props.radius Optional: Border-Radius ('none', 's', 'm')
 * @param {string} props.size Optional: die Größe des Containers ('s', 'm', 'l', 'fullWidth')
 * @param {string} props.textAlign Optional: Ausrichtung des Texts
 * @param {bool} props.reverseOnMobile Optional: Dreht die ausrichtung der Spalten (Mobil: Zeilen) um
 *
 * @example <Container padded size="m">...</Container>
 */
const Container = styled.div`
    margin-left: auto;
    margin-right: auto;
    padding: ${({ padded }) => (padded ? `${gaps.xxl} ${gaps.l}` : 0)};
    width: 100%;

    ${flexDirectionable()};
    ${gapable()};
    ${itemsAlignable()};
    ${layerable(0)};
    ${roundable('none')};
    ${sizable()};
    ${textAlignable()};
    ${justifyable()};

    ${mq.medium`
        ${displayable('block')};
    `};

    ${({ reverseOnMobile }) =>
        reverseOnMobile &&
        css`
            ${mq.mediumDown`
                display: flex;
                flex-direction: column-reverse;
                align-items: flex-start;
            `};
        `};

    ${mq.large`padding: ${({ padded }) => (padded ? gaps.xxl : 0)};`};

    ${({ grid, cols, gridGap }) =>
        grid &&
        cols &&
        css`
            > * {
                margin-top: 1em;
                &:first-child {
                    margin-top: 0;
                }

                ${({ reverseOnMobile }) =>
                    reverseOnMobile &&
                    `
                    &:nth-child(2) {
                        margin-top: 0;
                    }
                `};
            }

            ${mq.medium`
                @supports (display: grid) {
                    display: grid;
                    grid-template-columns: ${'1fr '.repeat(cols - 1)};
                    grid-column-gap: ${gridGap || '1.65em'};
                    grid-row-gap: ${gridGap || '1.65em'};
                }
                & > * {
                    margin-top: 0;
                }
            `};

            ${mq.large`grid-template-columns: ${'1fr '.repeat(cols)};`};
        `};

    ${({ grid, display, layout }) => {
        if (!layout) {
            return null;
        }

        if (grid || display === 'grid') {
            return mq.medium`grid-template-columns: ${layout.split('-').join('fr ')}fr`;
        }

        if (display === 'flex') {
            return layout
                .split('-')
                .map(
                    (col, i) => `
                        > *:nth-child(${i + 1}) {
                            width: ${parseInt(col, 10) * (100 / 12)}%;
                        }
                    `
                )
                .join();
        }

        return null;
    }};
`;

Container.propTypes = {
    alignItems: itemsAlignable.propType,
    children: PropTypes.node.isRequired,
    direction: flexDirectionable.propType,
    display: displayable.propType,
    gap: gapable.propType,
    grid: PropTypes.bool,
    gridGap: PropTypes.string,
    justify: justifyable.propType,
    layer: layerable.propType,
    layout: PropTypes.string,
    padded: PropTypes.bool,
    radius: roundable.propType,
    size: sizable.propType,
    textAlign: textAlignable.propType,
};

Container.defaultProps = {
    alignItems: null,
    direction: null,
    gap: null,
    grid: null,
    gridGap: null,
    justify: null,
    layer: null,
    layout: null,
    padded: null,
    size: null,
    textAlign: null,
};

export default Container;
