import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';

import { getImageFromNode } from 'helpers/utils';

import Section from 'ui-kit/section';
import Container from 'ui-kit/container';
import Teaserbox from 'ui-kit/teaserbox';
import { Headline } from 'ui-kit/typography';

/**
 * Erzeugt eine oder mehrere (Normalerweise zwei Teaserboxen)
 * in einer Section gewrappt hinzu. Wird als Seitenabschluss verwendet
 * @param {array} props.boxes Optional: Array aus Strings mit Keys für die möglichen Boxen:
 *      Mögliche Werte: ['recipes', 'originAndQuality', 'apprenticeship', 'jobOffers']
 *      Standard: ['recipes', 'originAndQuality']
 * @param {string} props.bg Optional: Farbe / Hintergrund (Siehe Section)
 * @param {string} props.headline Optional: Überschrift
 * @example <TeaserSection boxes={['recipes', 'apprenticeship']} />
 */
const TeaserSection = props => (
    <StaticQuery
        query={graphql`
            {
                teaserboxImages: allFile(
                    filter: { relativePath: { glob: "teaserboxes/teaserbox-*.jpg" } }
                ) {
                    edges {
                        node {
                            relativePath
                            childImageSharp {
                                fluid(maxWidth: 605, quality: 80) {
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                    }
                }
            }
        `}
        render={data => {
            const { teaserboxImages } = data;
            const { boxes, bg, headline } = props;

            const teaserboxes = {
                recipes: (
                    <Teaserbox
                        title="Rezepte für jeden Anlass"
                        text="Verwöhnen Sie sich und Ihre Gäste."
                        icon="arrowCircleRight"
                        href="/rezepte"
                        key="/rezepte"
                        image={
                            getImageFromNode(teaserboxImages, 'teaserboxes/teaserbox-rezepte.jpg')
                                .node.childImageSharp
                        }
                        imageAlt="Tranchiertes Rinderfilet neben Spargel"
                        layer={1}
                    />
                ),
                originAndQuality: (
                    <Teaserbox
                        title="Klare Herkunft und Qualität"
                        text="Darauf können Sie bei uns vertrauen."
                        icon="arrowCircleRight"
                        href="/herkunft-und-qualitaet"
                        key="/herkunft-und-qualitaet"
                        image={
                            getImageFromNode(
                                teaserboxImages,
                                'teaserboxes/teaserbox-herkunft-und-qualitaet.jpg'
                            ).node.childImageSharp
                        }
                        imageAlt="Landwirt, der ein Kalb auf dem Arm hält"
                        layer={1}
                    />
                ),
                apprenticeship: (
                    <Teaserbox
                        title="Ausbildung bei SBF"
                        text="Der richtige Start ins Berufsleben."
                        icon="arrowCircleRight"
                        href="/karriere/stellenangebote-und-ausbildungsberufe#ausbildungsberufe"
                        key="/karriere/stellenangebote-und-ausbildungsberufe#ausbildungsberufe"
                        image={
                            getImageFromNode(
                                teaserboxImages,
                                'teaserboxes/teaserbox-ausbildungsberufe.jpg'
                            ).node.childImageSharp
                        }
                        imageAlt="Vier unterschiedliche Auszubildene in selbstbewussten Posen"
                        layer={1}
                    />
                ),
                jobOffers: (
                    <Teaserbox
                        title="Stellenangebote"
                        text="Wir suchen vielleicht genau Sie!"
                        icon="arrowCircleRight"
                        href="/karriere/stellenangebote-und-ausbildungsberufe#stellenangebote"
                        key="/karriere/stellenangebote-und-ausbildungsberufe#stellenangebote"
                        image={
                            getImageFromNode(
                                teaserboxImages,
                                'teaserboxes/teaserbox-stellenangebote.jpg'
                            ).node.childImageSharp
                        }
                        layer={1}
                    />
                ),
            };

            return (
                <Section bg={bg}>
                    <Headline level="h2" gap="l" textAlign="center">
                        {headline}
                    </Headline>
                    <Container size="m" grid layout="6-6" cols="2" alignItems="center">
                        {boxes.map(box => teaserboxes[box])}
                    </Container>
                </Section>
            );
        }}
    />
);

TeaserSection.propTypes = {
    headline: PropTypes.string,
    boxes: PropTypes.arrayOf(PropTypes.string),
    bg: PropTypes.string,
};

TeaserSection.defaultProps = {
    headline: 'Das könnte Sie auch interessieren',
    boxes: ['recipes', 'originAndQuality'],
    bg: null,
};

export default TeaserSection;
