import React from 'react';
import PropTypes from 'prop-types';
import { rem, rgba } from 'polished';
import { Link } from 'gatsby';
import styled from 'styled-components';

import { mq, globalFramePadding } from 'helpers/stylehelpers';
import { colors } from 'helpers/variables';

import MetaNavigation from './meta-navigation';

// import Search from './search';
import Toggle from './toggle';
import Navigation from './navigation';

/** Wrapper um den Header herum */
const Wrapper = styled.header`
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: ${rem(15)};
    position: relative;
    transition: padding 0.5s;
    width: 100%;

    ${mq.mediumDown`
        &:before {
            background-color: ${rgba(colors.black, 0.5)};
            content: '';
            height: 100%;
            height: 100vh;
            right: 0;
            opacity: 0;
            position: absolute;
            top: 0;
            transition: opacity 0.5s, transform 0.5s, width 0.5s;
            width: 0;
            z-index: 2;

            ${({ offCanvasActive }) =>
                offCanvasActive &&
                `
                opacity: 1;
                width: 100%;
                width: 100vw;

            `};
        }

        ${({ searchActive }) =>
            searchActive &&
            `
            padding-top: ${rem(60)};
        `}
    `};

    ${mq.large`
        justify-content: space-between;
        padding-top: ${rem(20)};
        padding-bottom: ${rem(20)};
    `};

    ${globalFramePadding};
`;

/** Wrapper um das Logo herum */
const LogoLink = styled(Link)`
    margin: 0 ${rem(15)} 0 0;
    max-width: ${rem(160)};
    display: flex;
    align-items: flex-start;

    ${mq.medium`
        max-width: ${rem(180)};
    `};

    ${mq.large`
        margin: 0 ${rem(30)} 0 0;
        max-width: ${rem(200)};
    `};

    ${mq.xlarge`
        max-width: ${rem(250)};
    `};
`;

/** Logo als Bild */
const Logo = styled.img`
    text-align: center;
    width: 100%;
`;

/** Toggler */
const StyledToggle = styled(Toggle)`
    flex: 0 0 auto;
    position: relative;

    ${({ increaseZIndex }) =>
        increaseZIndex &&
        `
        z-index: 10;
    `};

    ${mq.large`
        display: none;
    `};
`;

/**
 * Wrapper für Suche & Meta
 */
const SearchAndMetaWrapper = styled.div`
    ${mq.large`
        align-items: center;
        display: flex;
        justify-content: flex-end;
        margin: 0 0 ${rem(20)} 0;
    `};
    ${mq.xlarge`
        margin-bottom: ${rem(25)};
    `};
`;

/** MetaNavigation */
const StyledMetaNavigation = styled(MetaNavigation)`
    display: none;
    position: relative;
    ${mq.large`
        display: flex;
    `};
`;

/** Suche */
// const StyledSearch = styled(Search)`
//     ${fontSizes.xs};
//     max-height: ${rem(30)};
//     transition: transform 0.5s;

//     ${mq.mediumDown`
//         position: absolute;
//         top: -${rem(30)};
//         left: ${rem(15)};
//         width: calc(100% - 30px);

//         ${({ isActive }) =>
//             isActive &&
//             `
//             transform: translateY(${rem(45)});
//         `};

//     `};

//     ${mq.large`
//         margin: 0 ${rem(30)} 0 0;
//         max-width: ${rem(250)};
//     `};
// `;

/** Wrapper für die Navigation + Meta */
const NavigationWrapper = styled.div`
    justify-content: flex-end;
    flex: 0 0 auto;
    z-index: 3;
`;

/**
 * Header Komponente, die Logo, Navi und Meta darstellt
 * @param {Object} props.navStructure Die Struktur der Navigation
 */
class Header extends React.Component {
    state = {
        offCanvasActive: false,
        searchActive: false,
    };

    /**
     * Toggelt entweder Navi oder Suche (mobil)
     * @param  {MouseEvent} evt Klick-Event
     */
    toggleElement = evt => {
        evt.preventDefault();
        const body = document.getElementsByTagName('body')[0];
        const toggleType = evt.currentTarget.getAttribute('data-toggletype');

        body.classList.toggle('off-canvas-active');

        this.setState(prevState => ({
            [toggleType]: !prevState[toggleType],
        }));
    };

    /**
     * Render
     */
    render() {
        const { pageStructure, logo } = this.props;
        const { offCanvasActive, searchActive } = this.state;

        return (
            <Wrapper searchActive={searchActive} offCanvasActive={offCanvasActive} id="top">
                {/* <StyledToggle
                    icon={searchActive ? 'close' : 'search'}
                    onClick={this.toggleElement}
                    data-toggletype="searchActive"
                /> */}
                <LogoLink to="/">
                    <Logo src={logo.image} alt={logo.alt} title={logo.title} />
                </LogoLink>
                <NavigationWrapper>
                    <SearchAndMetaWrapper>
                        {/* <StyledSearch
                            isActive={searchActive}
                            onSubmit={evt => {
                                console.log('Suche abgeschickt', evt);
                                evt.preventDefault();
                            }}
                        /> */}
                        <StyledMetaNavigation
                            items={[
                                {
                                    title: 'Händlersuche',
                                    href: '/service-und-kontakt/haendlersuche',
                                },
                                {
                                    title: 'Kontakt',
                                    href: '/service-und-kontakt/kontakt',
                                },
                                { title: 'FAQ', href: '/service-und-kontakt/faq' },
                            ]}
                        />
                    </SearchAndMetaWrapper>

                    <StyledToggle
                        icon={offCanvasActive ? 'close' : 'bars'}
                        aria-label={offCanvasActive ? 'Schließe Navigation' : 'Öffne Navigation'}
                        onClick={this.toggleElement}
                        data-toggletype="offCanvasActive"
                        increaseZIndex
                    />
                    <Navigation pageStructure={pageStructure} offCanvasActive={offCanvasActive} />
                </NavigationWrapper>
            </Wrapper>
        );
    }
}

Header.propTypes = {
    pageStructure: PropTypes.arrayOf(PropTypes.object).isRequired,
    logo: PropTypes.shape({
        src: PropTypes.string,
        alt: PropTypes.string,
        title: PropTypes.string,
    }).isRequired,
};

export default Header;
