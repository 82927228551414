import styled from 'styled-components';
import React from 'react';
import PropTypes from 'prop-types';

import { fontSizes } from 'helpers/variables';
import { mq } from 'helpers/stylehelpers';
import {
    displayable,
    gapable,
    layerable,
    roundable,
    bgColorable,
    textColorable,
} from 'helpers/traits';
import { buttonTextColor } from 'helpers/themehelpers';

import Container from './container';

/**
 * Erzeugt einen Tag
 * @param {string} props.children Der Inhalt des Tags
 * @param {string} props.display Optional: Die Darstellung (block/inline/...)
 * @param {string} props.gap Optional: Der Abstand nach unten ('s', 'm', 'l', 'xl', 'xxl', 'xxxl')
 * @param {number} props.layer Optional: Die Ebene/Größe des Schattens
 * @param {string} props.size: Optional: Die Größe des Tags ('s', 'm', 'l')
 * @param {string} props.bgColor: Optional: Die Farbe des Tags
 *
 * @example <Tag display="block" gap="l" layer={2} size="s">Test</Tag>
 */
const Tag = styled.div`
    ${({ size }) => fontSizes[size] || fontSizes.xs};
    color: ${buttonTextColor};
    padding: 0.35em 0.8em;
    text-align: center;
    ${displayable('inline-block')};
    ${gapable()};
    ${layerable()};
    ${roundable('s')};
    ${({ theme: { main: theme } }) => bgColorable(`${theme}|primary`)};
    ${({ theme: { main: theme } }) => textColorable(`${theme}|buttonTextColor`)};
`;

Tag.propTypes = {
    bgColor: bgColorable.propType,
    children: PropTypes.node.isRequired,
    display: displayable.propType,
    gap: gapable.propType,
    layer: layerable.propType,
    size: PropTypes.oneOf(['xs', 's', 'm', 'l']),
};

Tag.defaultProps = {
    bgColor: null,
    display: null,
    gap: null,
    layer: null,
    size: 's',
};

export default Tag;

/**
 * Wrappt Buttons in einer Button-Group
 * @param {string} props.children Der Inhalt der ButtonGroup
 * @param {string} props.className Optional: Klasse für erweitertest Styling
 * @param {string} props.size Optional: Klasse für erweitertest Styling
 */
export const TagGroup = styled(({ className, children, size }) => (
    <Container
        className={className}
        display="flex"
        size={size}
        justify="center"
        alignItems="baseline"
    >
        {children}
    </Container>
))`
    ${gapable()};
    justify-content: flex-start;

    > ${Tag} {
        margin-right: 1rem;

        ${mq.mediumDown`
            margin-bottom: 0.75em;
        `};

        ${mq.medium`
            margin-right: 1rem;
            width: auto;
            &:last-child() {
                margin-right: 0;
            }
        `};
    }
`;

TagGroup.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
    size: PropTypes.string,
};

TagGroup.defaultProps = {
    className: null,
    size: 'l',
};
