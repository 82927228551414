import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';

const propTypes = {
    children: PropTypes.node.isRequired,
    theme: PropTypes.shape({ main: PropTypes.string }),
};

const defaultProps = {
    theme: { main: 'sbf' },
};

/**
 * Stellt einen Theming-Context zur Verfügung
 * @param {object} props.children Kinder innerhalb des Theming-Kontexts
 * @param {object} props.theme Theme Object mit {main: 'sbf|vielLeicht|...'}
 *
 * @example <ThemeContainer theme={{main: 'sbf}}><Layout ...</Layout></ThemeContainer>
 */
const ThemeContainer = ({ children, theme }) => (
    <ThemeProvider theme={theme}>
        <>{children}</>
    </ThemeProvider>
);

ThemeContainer.propTypes = propTypes;
ThemeContainer.defaultProps = defaultProps;

export default ThemeContainer;
