import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { rem, rgba } from 'polished';
import styled, { css } from 'styled-components';
import theme from 'styled-theming';

import { colors } from 'helpers/variables';
import { primaryColor, buttonTextColor } from 'helpers/themehelpers';
import { globalFramePadding, mq } from 'helpers/stylehelpers';

/** Theming */
const dividerColor = theme('main', {
    sbf: rgba(colors.white, 0.25),
    vielLeicht: rgba(colors.black, 0.1),
});

/** Wrapper der Komponente */
const Wrapper = styled.footer`
    ${globalFramePadding};
    /* Code oben (padding) setzt globles Padding für den Seitenrand */
    align-items: center;
    background-color: ${primaryColor};
    display: flex;
    padding-top: 0.75em;
    padding-bottom: 0.75em;
    justify-content: space-between;
    flex-direction: column;

    ${props =>
    props.marginToContent &&
    css`
            margin-top: ${rem(30)};
            margin-top: var(--gap-xxxl);
        `}

    ${mq.mediumDown`
        font-size: ${rem(15)};
    `};

    ${mq.xlarge`
        padding-bottom: 1em;
        padding-top: 1em;
        flex-direction: row;
    `};
`;

/** Wrapper für den Copyright Hinweis */
const Copyright = styled.strong`
    text-align: center;
    color: ${buttonTextColor};
    ${mq.largeDown`
        border-bottom: 1px solid ${dividerColor};
        margin: 0.8em 0;
        padding-bottom: 0.8em;
        width: 100%;
    `};
`;

/** Wrapper der die Links zum rechtlichen hält */
const LegalLinks = styled.div`
    color: ${buttonTextColor};
`;

const StyledLink = styled(Link)`
    &,
    &:hover,
    &:focus {
        color: ${buttonTextColor};
        text-decoration: none;
    }
    &:hover {
        text-decoration: underline;
    }

    &:first-child {
        margin-right: 0.5em;
    }
    &:last-child {
        margin-left: 0.5em;
    }
`;

const StyledLinkA = styled.a`
    &,
    &:hover,
    &:focus {
        color: ${buttonTextColor};
        text-decoration: none;
    }
    &:hover {
        text-decoration: underline;
    }

    &:first-child {
        margin-right: 0.5em;
    }
    &:last-child {
        margin-left: 0.5em;
    }
`;

/** Footer Komponente */
const Footer = ({ marginToContent, ...otherProps }) => (
    <Wrapper marginToContent={marginToContent} {...otherProps}>
        <Copyright>&copy; {new Date().getFullYear()} Südbayerische Fleischwaren GmbH</Copyright>
        <LegalLinks>
            <StyledLinkA
                href="https://verbund.edeka/verantwortung/handlungsfelder/gesellschaft/menschenrechte/"
                target="_blank"
                rel="nofollow noreferrer"
            >
                Human Rights
            </StyledLinkA>
            |&nbsp;
            <StyledLink to="/impressum">Impressum</StyledLink> |
            <StyledLink to="/datenschutz">Datenschutz</StyledLink>
        </LegalLinks>
    </Wrapper>
);

Footer.propTypes = {
    marginToContent: PropTypes.bool,
};

Footer.defaultProps = {
    marginToContent: true,
};

export default Footer;
