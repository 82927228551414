import React from 'react';
import styled, { css } from 'styled-components';
import { rem } from 'polished';
import PropTypes from 'prop-types';

import { colors } from 'helpers/variables';
import { gapable, textAlignable } from 'helpers/traits';
import { mq } from 'helpers/stylehelpers';
import bgPattern from 'assets/images/bg-pattern.svg';

import Container from './container';

/** Mögliche Hintergründe */
const bgs = new Map([
    ['transparent', null],
    ['gray', `background-color: ${colors.gray100};`],
    ['pattern', `background: ${colors.blue100} url(${bgPattern}) 0 0 repeat;`],
]);

/**
 * Section
 * ----------------------------------------------------------------------------
 * Sektionen bilden die abwechselnd blauen und weißen Content-Bereiche der
 * Seite. Abhängig von der Farbigkeit der letzten Sektion wird auch der
 * PreFooter sowie der Footer eingefärbt.
 *
 * Über das `container` Attribut kann außerdem ein Container mit der übergebenen
 * Größe integriert werden:
 *
 * @param {string} props.bg Optional: die Hintergrundfarbe
 * @param {node} props.children Inhalte der Section
 * @param {string} props.gap Optional: der Abstand nach unten ('s', 'm', 'l', 'xl', 'xxl', 'xxxl')
 * @param {string} props.textAlign Optional: Ausrichtung des Texts
 *
 * @example <SectionElement container="m">...</SectionElement>
 */
const SectionElement = styled.section`
    ${({ bg }) => bgs.get(bg)};
    padding: ${rem(30)} ${rem(15)} ${rem(45)};
    ${mq.large`padding: ${rem(45)} ${rem(15)} ${rem(55)};`};
    ${mq.xlarge`padding: ${rem(60)} ${rem(15)} ${rem(70)};`};
    ${gapable()};
    ${textAlignable()};

    &:last-of-type {
        padding-bottom: ${rem(70)};
        ${mq.large`padding-bottom: ${rem(85)}`};
        ${mq.xlarge`padding-bottom: ${rem(118)}`};
    }

    ${({ bg }) =>
        /* Abstandsanpassung, wenn Sektion mit Hintergrund letztes Kind ist */
        bg &&
        css`
            + footer {
                margin-top: 0;
            }
        `};

    ${({ smallPaddingBottom }) =>
        smallPaddingBottom &&
        `
        padding-bottom: 1em !important;
    `};
`;

SectionElement.propTypes = {
    bg: PropTypes.string,
    children: PropTypes.node.isRequired,
    gap: gapable.propType,
    textAlign: textAlignable.propType,
    smallPaddingBottom: PropTypes.bool,
};

SectionElement.defaultProps = {
    bg: null,
    gap: null,
    textAlign: null,
    smallPaddingBottom: null,
};

/**
 * Erzeugt eine Sektion
 *
 * @param {string} props.bg Optional: die Hintergrundfarbe
 * @param {string} props.container Optional: ('s', 'm', 'l', 'fullWidth')
 * @param {node} props.children? Inhalte der Section
 * @param {string} props.id Optional: ID für Ankersprung
 * @param {string} props.gap Optional: der Abstand nach unten ('s', 'm', 'l', 'xl', 'xxl', 'xxxl')
 * @param {string} props.textAlign Optional: Ausrichtung des Texts
 * @param {string} props.smallPaddingOnly Optional: Weniger Padding am Ende der Sektion
 *
 * @example <Section container="m">...</Section>
 */
const Section = ({ container, children, id, ...props }) => (
    <SectionElement {...props} id={id}>
        {container ? <Container size={container}>{children}</Container> : children}
    </SectionElement>
);

Section.propTypes = {
    id: PropTypes.string,
    bg: PropTypes.string,
    children: PropTypes.node.isRequired,
    container: PropTypes.string,
    gap: gapable.propType,
    textAlign: textAlignable.propType,
};

Section.defaultProps = {
    id: null,
    bg: null,
    container: null,
    gap: null,
    textAlign: null,
};

export default Section;
