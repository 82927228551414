import styled, { css } from 'styled-components';
import React from 'react';
import PropTypes from 'prop-types';
import { rem } from 'polished';

import { mq } from 'helpers/stylehelpers';
import {
    primaryColor,
    primaryColorDarkened,
    buttonTextColor,
    buttonTextColorHover,
    ghostButtonTextColor,
    ghostButtonTextColorHover,
} from 'helpers/themehelpers';
import { fontSizes } from 'helpers/variables';
import { displayable, gapable, layerable, roundable, justifyable } from 'helpers/traits';

import Container from './container';
import LinkWrapper from './link-wrapper';

/**
 * Erzeugt einen Button
 * @param {string} props.children Der Inhalt des Buttons (zumeist Text)
 * @param {string} props.className Optional: Klasse für erweitertest Styling
 * @param {string} props.linkTo: Optional: Link des Buttons (kann intern, extern, Anker sein)
 * @param {string} props.onClick: Optional: onClickHandler
 * @param {string} props.display Optional: Die Darstellung (block/inline/...)
 * @param {string} props.gap Optional: Der Abstand nach unten ('s', 'm', 'l', 'xl', 'xxl', 'xxxl')
 * @param {string} props.ghost Optional: Die Darstellung (Ghost-Button / normaler Button)
 * @param {boolean} props.noHover Optional: Hover-Effekt deaktivieren
 * @param {number} props.layer Optional: Die Ebene/Größe des Schattens
 * @param {bool} props.rounded Optional: Ecken abgerundet oder gerade
 * @param {string} props.size: Optional: Die Größe des Buttons ('s', 'm')
 *
 *
 * @example <Button display="block" gap="l" ghost layer={2} rounded size="s">Test</Button>
 */
const Button = styled(({ className, children, linkTo, onClick, type, disabled }) => {
    let Element = (
        // eslint-disable-next-line react/button-has-type
        <button className={className} type={type} onClick={onClick} disabled={disabled}>
            {children}
        </button>
    );

    if (linkTo && !onClick) {
        Element = (
            <LinkWrapper to={linkTo} className={className}>
                {children}
            </LinkWrapper>
        );
    }

    return Element;
})`
    background-color: ${primaryColor};
    border: ${rem(2)} solid transparent;
    color: ${buttonTextColor};
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
    ${({ size }) => fontSizes[size] || fontSizes.m};
    padding: 0.35em 1em 0.45em;
    transition: background-color 150ms, color 150ms;
    line-height: 1.35;
    text-decoration: none;
    text-align: center;
    opacity: ${({ disabled }) => (disabled ? '0.7' : '1')};
    ${displayable('inline-block')};
    ${gapable()};
    ${layerable(1)};
    ${roundable('m')};

    ${({ noHover }) =>
        noHover
            ? null
            : css`
                  &:hover {
                      background-color: ${primaryColorDarkened};
                      color: ${buttonTextColorHover};
                      text-decoration: none;
                  }
              `};

    ${({ ghost }) =>
        ghost
            ? css`
                  border-color: ${primaryColor};
                  background-color: transparent;
                  color: ${ghostButtonTextColor};

                  &:hover {
                      border-color: transparent;
                      background-color: ${primaryColorDarkened};
                      color: ${ghostButtonTextColorHover};
                  }
              `
            : null};
`;

Button.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
    display: displayable.propType,
    gap: gapable.propType,
    ghost: PropTypes.bool,
    layer: layerable.propType,
    linkTo: PropTypes.string,
    noHover: PropTypes.bool,
    onClick: PropTypes.func,
    size: PropTypes.oneOf(['xs', 's', 'm']),
    type: PropTypes.oneOf(['button', 'submit']),
    disabled: PropTypes.bool,
};

Button.defaultProps = {
    className: null,
    display: null,
    gap: null,
    ghost: false,
    layer: null,
    linkTo: null,
    noHover: false,
    onClick: null,
    size: 's',
    type: 'button',
    disabled: false,
};

export default Button;

/**
 * Wrappt Buttons in einer Button-Group
 * @param {string} props.children Der Inhalt der ButtonGroup
 * @param {string} props.className Optional: Klasse für erweitertest Styling
 * @param {string} props.size Optional: Klasse für erweitertest Styling
 */
export const ButtonGroup = styled(({ className, children, size, justify }) => (
    <Container className={className} display="flex" size={size} justify={justify}>
        {children}
    </Container>
))`
    ${gapable()};

    > ${Button} {
        ${mq.mediumDown`
            margin-bottom: 0.75em;
            width: 100%;
        `};

        ${mq.medium`
            margin-right: 1rem;
            width: auto;
            &:last-child {
                margin-right: 0;
            }
        `};
    }
`;

ButtonGroup.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
    size: PropTypes.string,
    justify: justifyable.propType,
};

ButtonGroup.defaultProps = {
    className: null,
    size: 'l',
    justify: 'center',
};
