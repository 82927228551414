import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { FacebookF as FaFacebookFIcon } from 'styled-icons/fa-brands/FacebookF';
import { Xing as FaXingIcon } from 'styled-icons/fa-brands/Xing';
import { Youtube as FaYoutubeIcon } from 'styled-icons/fa-brands/Youtube';
import { AngleRight as FaAngleRightIcon } from 'styled-icons/fa-solid/AngleRight';
import { AngleUp as FaAngleUpIcon } from 'styled-icons/fa-solid/AngleUp';
import { AngleDown as FaAngleDownIcon } from 'styled-icons/fa-solid/AngleDown';
import { CaretDown as FaCaretDownIcon } from 'styled-icons/fa-solid/CaretDown';
import { CaretLeft as FaCaretLeftIcon } from 'styled-icons/fa-solid/CaretLeft';
import { CaretRight as FaCaretRightIcon } from 'styled-icons/fa-solid/CaretRight';
import { CaretUp as FaCaretUpIcon } from 'styled-icons/fa-solid/CaretUp';
import { LongArrowAltUp as FaLongArrowUp } from 'styled-icons/fa-solid/LongArrowAltUp';
import { ChevronCircleDown as FaChevronCircleDownIcon } from 'styled-icons/fa-solid/ChevronCircleDown';
import { Cogs as FaCogsIcon } from 'styled-icons/fa-solid/Cogs';
import { Heart as FaHeartIcon } from 'styled-icons/fa-solid/Heart';
import { Play as FaPlayIcon } from 'styled-icons/fa-solid/Play';
import { Search as FaSearchIcon } from 'styled-icons/fa-solid/Search';
import { Star as FaStarRegularIcon } from 'styled-icons/fa-regular/Star';
import { Star as FaStarSolidIcon } from 'styled-icons/fa-solid/Star';
import { TrashAlt as FaTrashAltIcon } from 'styled-icons/fa-regular/TrashAlt';

import { ReactComponent as ArrowCircleRight } from 'assets/images/icons/arrow-circle-right.svg';
import { ReactComponent as ArrowLeftIcon } from 'assets/images/icons/arrow-left.svg';
import { ReactComponent as ArrowRightIcon } from 'assets/images/icons/arrow-right.svg';
import { ReactComponent as BarsIcon } from 'assets/images/icons/bars.svg';
import { ReactComponent as ChickenIcon } from 'assets/images/icons/chicken.svg';
import { ReactComponent as CloseIcon } from 'assets/images/icons/close.svg';
import { ReactComponent as SteakIcon } from 'assets/images/icons/steak.svg';

import { colors } from 'helpers/variables';

/** Standard-Styles für die Icons */
const styledIcon = icon => styled(icon)`
    color: ${({ color }) => colors[color]};

    transform: rotate(${({ rotate }) => rotate || 0});
    transition: transform 150ms;
`;

/** Alle verfügbare Icons */
export const icons = {
    arrowCircleRight: styledIcon(ArrowCircleRight),
    arrowLeft: styledIcon(ArrowLeftIcon),
    arrowRight: styledIcon(ArrowRightIcon),
    longArrowUp: styledIcon(FaLongArrowUp),
    angleDown: styledIcon(FaAngleDownIcon),
    angleUp: styledIcon(FaAngleUpIcon),
    angleRight: styledIcon(FaAngleRightIcon),
    bars: styledIcon(BarsIcon),
    caretDown: styledIcon(FaCaretDownIcon),
    caretLeft: styledIcon(FaCaretLeftIcon),
    caretRight: styledIcon(FaCaretRightIcon),
    caretUp: styledIcon(FaCaretUpIcon),
    chevronCircleDown: styledIcon(FaChevronCircleDownIcon),
    chicken: styledIcon(ChickenIcon),
    close: styledIcon(CloseIcon),
    cogs: styledIcon(FaCogsIcon),
    heart: styledIcon(FaHeartIcon),
    play: styledIcon(FaPlayIcon),
    search: styledIcon(FaSearchIcon),
    starRegular: styledIcon(FaStarRegularIcon),
    starSolid: styledIcon(FaStarSolidIcon),
    steak: styledIcon(SteakIcon),
    trash: styledIcon(FaTrashAltIcon),
    facebook: styledIcon(FaFacebookFIcon),
    youtube: styledIcon(FaYoutubeIcon),
    xing: styledIcon(FaXingIcon),
};

/**
 * Icon Componente
 * @param {string} props.type Typ des Icons, aka der Name des gewünschten Icons
 * @param {string} props.className Optional: zusätzliche CSS-Klassen
 * @param {string} props.color Optional: Typ des Icons
 * @param {string} props.rotate Optional: Rotation des Icons
 */
const Icon = ({ type, className, color, rotate }) => {
    const IconElement = icons[type];
    return <IconElement className={`icon ${className}`} color={color} rotate={rotate} />;
};

Icon.propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
    rotate: PropTypes.string,
    type: PropTypes.oneOf(Object.keys(icons)).isRequired,
};

Icon.defaultProps = {
    className: null,
    color: 'currentColor',
    rotate: null,
};

export default Icon;
